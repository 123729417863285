/*
 * @Author: jay
 * @Date: 2021-07-15 14:19:08
 * @LastEditTime: 2021-07-26 09:54:05
 * @Description: file content
 */
import Loadable from 'react-loadable';
import {Icon} from 'antd-mobile';

export default (loader:any,render?:any)=>{
    if(!render){
        return Loadable({
            loader,
            loading: () => (<Icon type="loading" className="loading" size="lg" />)
        })
    }
    return Loadable({
        loader,
        loading: () => (<Icon type="loading" className="loading" size="lg" />),
        render
    })
}