/*
 * @Author: jay
 * @Date: 2022-02-28 10:06:24
 * @LastEditTime: 2022-04-19 16:23:48
 * @Description: file content
 */
import loadable from "@/component/loadable";
export default [
  {
    path: "/home",
    component: loadable(() => import("@/page/home")),
    exact: true,
  },
  {
    path: "/login",
    component: loadable(() => import("@/page/Login")),
    exact: true,
  },
  {
    path: "/deposit",
    component: loadable(() => import("@/page/Deposit")),
    exact: true,
  },
  {
    path: "/activity",
    routes: [
      {
        path: "/activity/flash_sale/:id",
        exact: true,
        component: loadable(() => import("@/page/shopping")),
      },
      {
        path: "/activity/shopdetails/:id",
        exact: true,
        component: loadable(() => import("@/page/shopping/details")),
      },
      {
        path: "/activity/address/:id",
        exact: true,
        component: loadable(() => import("@/page/shopping/address")),
      },
      {
        path: "/activity/v_welfare/:id",
        exact: true,
        component: loadable(() => import("@/page/vwelfare")),
      },
      {
        path: "/activity/register/:type/:id",
        exact: true,
        component: loadable(() => import("@/page/Register")),
      },
      {
        path: "/activity/jackpot/:id",
        exact: true,
        component: loadable(() => import("@/page/carve_up")),
      },
      {
        path: "/activity/game_charge/:id",
        component: loadable(() => import("@/page/Deposit")),
        exact: true,
      },
      {
        path: "/activity/game_charge/payDetails/:orderSn",
        component: loadable(() => import("@/page/Deposit/payDetails")),
        exact: true,
      },
      {
        path: "/activity/coupon/:orderSn",
        component: loadable(() => import("@/page/Deposit/coupon")),
        exact: true,
      },
      {
        path: "/activity/lottery/payDetails",
        component: loadable(() => import("@/page/Lottery/payDetails")),
        exact: true,
      },
      {
        path: "/activity/lottery/:id",
        component: loadable(() => import("@/page/Lottery")),
        exact: true,
      },
      {
        path: "/activity/recharge_lottery/payDetails",
        component: loadable(() => import("@/page/Lottery/payDetails")),
        exact: true,
      },
      {
        path: "/activity/recharge_lottery/:id",
        component: loadable(() => import("@/page/Lottery")),
        exact: true,
      },
      {
        path: "/activity/recharge/coupon/:orderSn",
        component: loadable(() => import("@/page/Lottery/coupon")),
        exact: true,
      },
      {
        path: "/activity/invite/:id",
        component: loadable(() => import("@/page/Invite")),
        exact: true,
      },
    ],
  },
  {
    redirect: true,
    path: "/activity",
  },
];
