/*
 * @Author: xyh
 * @Date: 2021-11-26
 * @LastEditTime: 2022-02-15
 * @Description: 路由修改
 */

import React from "react";
import { Route, Switch, Redirect, HashRouter } from "react-router-dom";
import routes from "./config";
//配置主路由

// 递归路由
const createFixRoute = (routes: any[]) => {
  const recursion: any = (routesList: any[]) =>
    routesList.map((item) => {
      if (item.redirect) return <Redirect key={item.path} to={item.path} />;
      let resultDom: any = item.component && (
        <Route
          key={item.path}
          path={item.path}
          component={item.component}
          {...item}
        />
      );
      if (item.routes)
        resultDom = [...recursion(item.routes), resultDom ? resultDom : null];
      return resultDom;
    });
  return recursion(routes).flat("Infinity");
};

const Routes: React.FC = () => {
  return (
    <HashRouter>
      <Switch>
        {createFixRoute(routes)}
      </Switch>
    </HashRouter>
  );
};
export default Routes;
